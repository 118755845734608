@media (max-width: $screen-sm-max) {

  .footer-consult {
    text-align: center;

    .konsultaciya_button {
      display: block;
      margin: 0 auto;

      a {
        display: block;
        width: 100%;
        margin-bottom: 10px;
      }

      &:nth-last-of-type(2) {
        margin-top: 30px;
        margin-bottom: 10px;
      }

      @media (min-width: $screen-sm) {
        max-width: 190px;
      }
    }

    @media (min-width: $screen-sm) {
      float: left;
      width: 50%;
      padding-left: 15px;
    }
  }

  .footer-consult__header {
    color: #333333;
    font-family: 'PT Sans Narrow', sans-serif;
    font-size: 22px;
    font-weight: 400;
    text-transform: uppercase;
  }

  .footer-consult__call-us {
    display: block;
  }

  .footer-consult__phone {
    font-family: 'PT Sans Narrow', sans-serif;
    font-size: 16px;
    letter-spacing: 0.4px;
    color: #333333;

    &:last-of-type {
      font-size: 20px;
      font-weight: 700;
      letter-spacing: 0.6px;
      color: #b5282f;
    }
  }


}