
#i-material-1 {
  display: block;
  width: 49px;
  height: 49px;
  float: left;
  background: url('../images/prezentaciya.jpg') no-repeat center center;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: -10px;
}

#i-material-2 {
  display: block;
  width: 49px;
  height: 49px;
  float: left;
  background: url('../images/buklet-treningov.jpg') no-repeat center center;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: -10px;
}

#i-material-3 {
  display: block;
  width: 49px;
  height: 49px;
  float: left;
  background: url('../images/statiya-iz-nbzh-4.jpg') no-repeat center center;
  border-radius: 5px;
  overflow: hidden;
}

#i-material-4 {
  display: block;
  width: 49px;
  height: 49px;
  float: left;
  background: url('../images/audit_kadrovoj_funkcii.jpg') no-repeat center center;
  border-radius: 5px;
  overflow: hidden;
}

#i-material-5 {
  display: block;
  width: 49px;
  height: 49px;
  float: left;
  background: url('../images/perechen_treningov.jpg') no-repeat center center;
  border-radius: 5px;
  overflow: hidden;
}

#i-material-6 {
  display: block;
  width: 49px;
  height: 49px;
  float: left;
  background: url('../images/professionlanoe_konsultirovanie_bankov.jpg') no-repeat center center;
  border-radius: 5px;
  overflow: hidden;
}

.info_material {
  clear: both;
  margin-bottom: 10px;
  min-height: 60px;

  @media (max-width: $screen-sm-max) {
    min-height: auto;

    div {
      display: none !important;
    }

    a {
      position: relative;
      top: 0;
      left: 0;
      float: none !important;
      padding-left: 12px;
      font-weight: normal;

      br {
        display: none;
      }

      &::before {
        position: absolute;
        top: 0.7em;
        left: 0;
        content: '';
        display: block;
        width: 7px;
        height: 1px;
        background-color: #247ec4;
      }
    }
  }

  @media (min-width: $screen-lg) {
    width: 100%;
  }
}

.material {
  display: block;
  padding-top: 6px;
  text-align: right;

  @media (max-width: $screen-sm-max) {
    &, &:visited {
      position: static !important;
      display: block;
      width: 190px;
      float: none !important;
      margin: 20px auto 10px !important;
      padding: 10px 0 8px;
      border-radius: 8px;
      background-color: #647b98;
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
      color: white !important;
      text-decoration: none;
    }
  }

  @media (min-width: $screen-lg) {
    width: 250px;
    margin-left: -20px;
    position: relative;
    left: -35px;
    top: 5px;
    font-size: 14px !important;
    font-weight: normal !important;
    color: #247ec4;
    border-top: 1px solid #e8e9e9;
  }
}