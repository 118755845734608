@media (min-width: $screen-lg) {
  .menu {
    position: absolute;
    left: 315px;
    top: 93px;
    width: 250px;
    height: 100px;
    font-size: 15px;
    z-index: 100;
    color: #333333;
  }

  body.en .menu {
    left: 290px;
  }

  .menu > ul > li a {
    width: 100px;
    padding: 5px 10px;
    display: block;
    text-align: center;
  }

  .menu > ul > .gall {
    position: relative;
    left: 14px;
    width: 70px;
  }

  .menu > ul > .gall a {
    width: 100px !important;
    padding-left: 20px !important;
  }

  .menu > ul > li > ul > li {
    width: 100%;

    a {
      margin: 0 auto;
    }
  }

  .menu > ul > li > ul > li a {
    color: #647b98 !important;
    background: white !important;
    border-right: none !important;
  }

  .menu > ul > li > ul > li a:hover {
    color: #b5282f !important;
    text-decoration: underline;
  }

  .menu > ul > li > ul {
    position: relative;
    left: -10px !important;
    border-radius: 0 0 8px 8px;
    overflow: hidden !important;
    width: 135px;
    background: white !important;
  }

  .menu > ul > li > ul > li:last-child {
    overflow: hidden;
    border-radius: 0 0 8px 8px;
    width: 135px;
  }

  .menu .gall > ul {
    left: 0 !important;
  }

  .menu ul li a, .menu ul li a:visited {
    display: block;
    text-decoration: none;
    height: 20px;
    text-align: center;
    color: #647b98;
    line-height: 20px;
    overflow: hidden;
    font-weight: bold;
    font-size: 15px;
    margin-right: -17px;
  }

  .menu ul li a {
    padding: 5px 10px;
    //width: 120px;
    border-right: 1px solid #ebebeb;
  }

  .menu > ul > li > a:hover {
    color: #b5282f !important;
    text-decoration: underline;
  }

  .gal {
    padding-right: 0;
  }

  .menu ul {
    padding: 0;
    margin: 0;
    list-style: none
  }

  .menu ul li {
    float: left;
    position: relative;
    color: #939393;

  }

  .menu ul li ul {
    display: none;

  }

  .menu ul li:hover a {
    color: #4c688c;
  }

  .menu ul li:hover ul {
    display: block;
    position: absolute;
    top: 30px;
    left: 0;

    background-color: black;
  }

  .menu ul li:hover ul li a {
    display: block;
    background: #587294;
    color: #000;
  }

  .menu ul li:hover ul li a:hover {
    background: #b5282f;
    color: #ffffff;
  }
}


