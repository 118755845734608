
.news {
  border-bottom: 1px solid #e8e9e9;
  margin-bottom: 25px;
  clear: both;
  padding-bottom: 5px;

  @media (max-width: $screen-sm-max) {
    margin-bottom: 50px;

    h4 {
      margin-left: 60px;
    }

    h4 + div {
      clear: none !important;
      margin-top: 15px;
      margin-bottom: 10px;
      margin-left: 60px;
    }
  }

  @media (min-width: $screen-lg) {
    width: 230px;
  }
}

@mixin news_img($url) {
  float: left;
  width: 49px;
  height: 49px;
  margin-right: 10px;
  background-image: url($url);

  @media (min-width: $screen-lg) {

  }
}

#news_img_1 {
  @include news_img('../images/img_1.png');
}

#news_img_2 {
  @include news_img('../images/img_2.png');
}

#news_img_3 {
  @include news_img('../images/img_3.png');
}

#news_img_4 {
  @include news_img('../images/img_4.png');
}

#news_img_5 {
  @include news_img('../images/img_1.png');
}

.pdf_img {
  width: 70px;
  height: 70px;
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
  background-image: url(../images/pdf_icon.jpg);
}

.newss {
  @include clearfix;

  padding-bottom: 5px;

  @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    float: left;
    width: 50%;
    text-align: center;
    border-right: 1px solid #e8e9e9;
  }

  @media (min-width: $screen-lg) {
    width: 255px;
    height: 520px;
    margin-top: 60px;
  }
}

.newss h4 {
  margin: 0;
  padding: 0;
  font-weight: bold;
  color: #b5282f;
  text-decoration: none;
  margin-bottom: 20px;
  font-size: 20px;
}

.news h4 {
  margin: 0;
  padding: 0;
  font-size: 15px;
  font-weight: bold;
  color: #b5282f;
  margin-bottom: 0px;
  text-decoration: none;

}

.news span {
  color: #8a8a8a;
  font-size: 14px;
  font-weight: bold;

}

.news p {
  margin: 0;
  text-align: left;
  color: #333333;
  font-size: 14px;
  font-weight: normal !important;
}

.news a {
  color: #247ec4;
  font-size: 14px;
  float: right;
  display: block;
  margin-top: 5px;
  margin-bottom: 5px;

  @media (max-width: $screen-sm-max) {
    margin-top: 10px;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  @media (min-width: $screen-lg) {
    margin-right: -10px;
  }
}

.newss a {
  color: #247ec4;
  font-size: 16px;
  font-weight: bold;
  float: left;
  position: relative;
  left: 13px;
  top: 10px;
  margin-right: 10px;

  @media (min-width: $screen-lg) {

  }
}
