.page-contacts {

  @media (max-width: $screen-xs-max) {
    #addr {
      width: auto !important;

      tr td > div {
        max-width: calc(100vw - 55px) !important;
        overflow: hidden !important;

        & > ymaps {
          width: 100% !important;
        }
      }

      td.addr {
        color: #333333;
        font-family: 'PT Sans Narrow', sans-serif;
        font-size: 18px;
        font-weight: 400;

        a {
          color: #247ec4;
          text-decoration: underline;
        }
      }

    }
  }

}