@mixin darken-background($opacity: 0.5) {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    display: block;
    background-color: rgba(0,0,0,$opacity);
    pointer-events: none;
    transition: 0.4s ease-in-out;
  }

  .container {
    position: relative;
    z-index: 1;
  }
}

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}