.table-responsive {

  max-width: 100%;
  overflow: hidden;

  table {
    width: 100% !important;
    max-width: 100% !important;
  }

  td {
    width: auto !important;
  }

}