
#breadz {
  margin-top: 15px;
  margin-bottom: 15px;
}

#breadz a {
  &,
  &:link,
  &:visited {
    font-size: 15px;
    font-weight: bold;
    color: #247ec4;
  }
}

#breadz a:hover {
  text-decoration: none;
  color: #b5282f;
}

span.bread_span {
  color: #C0565A;
  padding: 4px !important;
  position: relative;
  top: 1px;
  font-weight: bold;
}