#map {
  position: relative;

  img {
    margin-top: 25px;
  }

  @media (max-width: $screen-xs-max) {
    transform-origin: center;
  }

  @media (min-width: $screen-lg) {
    width: 429px;
    height: 260px;
  }
}

.map__wrapper  {
  @media (max-width: $screen-sm-max) {
    position: relative;
    min-height: 250px;

    #map {
      position: absolute;
      left: 50%;
      transform: scale(0.6) translate(-50%);
      transform-origin: left;
    }

    @media (min-width: 400px) {
      #map {
        transform: translate(-50%);
      }
    }
  }

}

#map .point_holder {
  position: absolute;
}

.point_holder .point {
  width: 1px;
  height: 1px;
  border: 2px #CC0000 solid;
  border-radius: 3px;
  background: #CC0000;
  display: block
}

.point_holder .cloud {
  bottom: -1px;
  display: none;
  left: -1px;
  position: absolute;
  width: auto;
  min-width: 112px;
  padding: 5px;
  z-index: 10;
}

.point_holder .cloud ul {
  border: 1px solid #CC0000;
  background: #FFFFFF;
  margin: 0;
  padding: 7px;
  list-style: none;
  border-radius: 10px;
  font-family: Arial, sans-serif;
  font-size: 11px;
}

.point_holder .cloud ul a {
  color: #666666;
  text-decoration: none;
}

.point_holder .cloud ul a:hover {
  color: #666666;
  text-decoration: underline;
}

.point_holder .point:hover .cloud {
  display: block;

  z-index: 50;

}

/* point_holder coords */
.point_holder.point1 { /* moskva */
  left: 244px;
  top: 82px;
}

.point_holder.point1 .cloud { /* moskva */
}

.point_holder.point2 { /* piter */
  left: 236px;
  top: 75px;
}

.point_holder.point2 .cloud { /* piter */
}

.point_holder.point3 { /* volgograd */
  left: 251px;
  top: 91px;
}

.point_holder.point3 .cloud { /* volgograd */
}

.point_holder.point4 { /* novorossiysk */
  left: 245px;
  top: 99px;
}

.point_holder.point4 .cloud { /* novorossiysk */
}

.point_holder.point5 { /* anapa */
  left: 240px;
  top: 94px;
}

.point_holder.point5 .cloud { /* anapa */
}

.point_holder.point6 { /* minsk */
  left: 232px;
  top: 87px;
}

.point_holder.point6 .cloud { /* minsk */
  width: 150px;
  top: -2px;
}

.point_holder.point7 { /* kiev */
  left: 232px;
  top: 94px;
}

.point_holder.point7 .cloud { /* kiev */
}

.point_holder.point8 { /* ryminia */
  left: 227px;
  top: 98px;
}

.point_holder.point8 .cloud { /* ryminia */
}

.point_holder.point9 { /* bolgaria */
  left: 229px;
  top: 104px;
}

.point_holder.point9 .cloud { /* bolgaria */
  top: -2px;
}

.point_holder.point10 { /* vengria */
  left: 220px;
  top: 95px;
}

.point_holder.point10 .cloud { /* vengria */
}

.point_holder.point11 { /* italia */
  left: 212px;
  top: 101px;
}

.point_holder.point11 .cloud { /* italia */
}

.point_holder.point12 { /* ispania */
  left: 198px;
  top: 109px;
}

.point_holder.point12 .cloud { /* ispania */
  top: -2px;
}

.point_holder.point13 { /* francia */
  left: 204px;
  top: 103px;
}

.point_holder.point13 .cloud { /* francia */
}

.point_holder.point14 { /* velikobritania */
  left: 196px;
  top: 85px;
}

.point_holder.point14 .cloud { /* velikobritania */
}

.point_holder.point15 { /* niderlandi */
  left: 204px;
  top: 90px;
}

.point_holder.point15 .cloud { /* niderlandi */
}

.point_holder.point16 { /* slovakia */
  left: 220px;
  top: 101px;
}

.point_holder.point16 .cloud { /* slovakia */
}

.point_holder.point17 { /* latvia */
  left: 229px;
  top: 80px;
}

.point_holder.point17 .cloud { /* latvia */
}

.point_holder.point18 { /* malta */
  left: 220px;
  top: 113px;
}

.point_holder.point18 .cloud { /* malta */
}

.point_holder.point19 { /* tadjikistan */
  left: 288px;
  top: 106px;
}

.point_holder.point19 .cloud { /* tadjikistan */
}

.point_holder.point20 { /* uzbekistan */
  left: 283px;
  top: 106px;
}

.point_holder.point20 .cloud { /* uzbekistan */
}

.point_holder.point21 { /* azerbaidjan */
  left: 256px;
  top: 107px;
}

.point_holder.point21 .cloud { /* azerbaidjan */
}

