.page-uslugi {

  @media (max-width: $screen-sm-max) {

    #center {

      .table-responsive:last-of-type,
      table.last-of-type {
        p:nth-last-of-type(1),
        p:nth-last-of-type(2) {
          display: none;
        }

        .table-responsive:last-of-type,
        table.last-of-type {
          display: none;
        }
      }


    }

  }

}

