body {
  background-image: url(../images/fon.png);
  margin: 0;
  padding: 0;
  font-family: 'PT Sans Narrow', sans-serif;
}

a {
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}

#header {
  height: 100px;
  margin: 0 auto;
  background-color: #ffffff;
  box-shadow: 1px 1px 3px 2px #a8a9ab;
  position: relative;
  z-index: 90;

  @media (max-width: $screen-sm-max) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }

  @media (min-width: $screen-lg) {
    width: 1005px;
    height: 125px;
    margin-top: 5px;
    border-radius: 10px;
  }
}

#logo {
  background-image: url('../images/logo.png');

  @media (max-width: $screen-sm-max) {
    span {
      display: none;
    }

    img {
      max-height: 50px;
      width: auto;
    }
  }

  @media (min-width: $screen-lg) {
    position: absolute;
    left: 45px;
    top: 13px;
    width: 154px;
    height: 86px;

    a {
      display: block;
      height: 92px;
      cursor: pointer;
    }

    span {
      color: #B5282F;
      font-size: 14px;
      font-weight: bold;
      left: 6px;
      position: relative;
      top: -7px;
      font-family: 'Calibri', sans-serif;
      letter-spacing: 1px;
    }
  }
}

#padding_li {
  @media (min-width: $screen-lg) {
    padding-left: 255px;
  }
}

#home {
  width: 65px;
  height: 15px;
  position: absolute;
  right: 52px;
  top: 41px;

  @media (max-width: $screen-sm-max) {
    display: none;
  }
}

#home img {
  margin-right: 6px;
  border: none;
}

#content {
  background-color: #ffffff;
  overflow: hidden;
  box-shadow: 1px 1px 3px 2px #a8a9ab;
  margin: 0 auto;
  margin-top: 20px;
  border-radius: 10px;

  @media (max-width: $screen-sm-max) {
    margin-top: 100px;
    padding: 10px;
    padding-bottom: 30px;
    display: flex;
    flex-flow: column;
    border-radius: 0;
  }

  @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    padding: 15px;
    padding-top:20px;
    flex-flow: row wrap;
  }

  @media (min-width: $screen-lg) {
    width: 1005px;
  }
}

#networks {
  width: 230px;
  height: 130px;
  margin-top: 12px;
  clear: both;
}

#networks img {
  float: right;
  margin-left: 5px;
  border: none;
}

/* Shantix Styles */

#right .news p {
  text-align: justify;
  font-size: 15px !important;
  font-family: 'PT Sans Narrow', sans-serif;
}

#right .news p font {
  font-family: 'PT Sans Narrow', sans-serif !important;
  font-size: 15px !important;
  font-weight: normal !important;
}

.nnn {
  color: #8a8a8a;
}

.Era {
  display: none !important;
}

#google {
  width: 140px;
  overflow: hidden;
  display: block;
  top: 0px;
  padding-bottom: 10px;
  left: 0px;
}

.kT {
  display: none !important;
}

.gc-reset, .pls-dropBottom {
  display: none !important;
}

#vk {
  top: 0px;
  padding-bottom: 10px;
  left: 0px;
}

#networks {
  margin-top: 14px;
  margin-bottom: 10px;
  margin-left: -7px;

  @media (max-width: $screen-sm-max) {
    display: none;
  }
}

.i-title {
  position: relative;
  top: 21px;
}

#right > div:nth-child(1n) > #news_img_1 {
  background: url('../images/news1.jpg') no-repeat center center !important;
}

#right > div:nth-child(2) > #news_img_1 {
  background: url('../images/news2.jpg') no-repeat center center !important;
}

#right > div:nth-child(3n) > #news_img_1 {
  background: url('../images/news3.jpg') no-repeat center center !important;
}

#news_img_1 {
  border-radius: 5px;
}

#partnerz tr td {
  display: block;
  width: 300px;
  height: 200px;
  border: 1px solid red;
}


#counters {
  margin-top: 5px;
}

.posmotret_vse_novosti a {
  color: #247ec4;
  font-size: 14px;
  float: right;
  margin-right: -10px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: block;
  text-decoration: underline !important;
  z-index: 999;
  position: relative;
  top: 390px;
  left: 20px;
}

p.posmotret_vse_novosti a:hover {
  text-decoration: none !important;
}

p.posmotret_vse_novosti a:visited {
  text-decoration: underline !important;
}

/*** Старт поведенческого блока ***/
#ostalis_voprosy {
  text-transform: uppercase;
  font-size: 30px;
}

#prefiks_v_kontente {
  font-size: 22px;
  color: #000000;
  top: -2px;
  right: 5px;
  position: relative;
  padding-left: 10px;
}

#telefon_v_kontente {
  font-size: 30px;
  color: #b5282f;
  font-weight: bold;
}

.otzyvy_button a {
  border: 1px solid #bbbbbb;
  color: #fff !important;
  font-size: 17px !important;
  text-decoration: none !important;
  text-align: center;
  line-height: 40px;
  display: block;
  width: 210px;
  height: 40px;
  font-style: normal;
  text-transform: uppercase;
  font-weight: normal;
  background: #b5282f;
  position: relative;
  margin-top: -15px;
  margin-bottom: 30px;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}

p.otzyvy_button a:hover {
  text-decoration: underline !important;
  background: #b5282f;
}

p.otzyvy_button a:visited {
  text-decoration: none !important;
}

.konsultaciya_button a {
  border: 1px solid #bbbbbb;
  color: #fff !important;
  font-size: 17px !important;
  text-decoration: none !important;
  text-align: center;
  line-height: 40px;
  display: block;
  width: 210px;
  height: 40px;
  font-style: normal;
  text-transform: uppercase;
  font-weight: normal;
  background: #b5282f;
  position: relative;
  border-radius: 10px;

  @media (min-width: $screen-lg) {
    margin-top: -15px;
    margin-bottom: 30px;
  }
}

p.konsultaciya_button a:hover {
  text-decoration: underline !important;
  background: #b5282f;
}

p.konsultaciya_button a:visited {
  text-decoration: none !important;
}

/*** Финиш поведенческого блока ***/