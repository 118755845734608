.konsmenu {

  @media (min-width: 400px) and (max-width: $screen-sm-max) {
    padding-right: 15px;

    ul {
      padding-left: 15px;
    }
  }

}