.page-report {

  #breadz {
    padding-right: 80px;
  }
}

.reviews-page {
  position: relative;

  .owl-item img {
    width: 100%;
    height: auto !important;
  }

  .owl-nav {
    position: absolute;
    top: -48px;
    right: 0;
  }

  .owl-prev,
  .owl-next {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    font-size: 0;
    color: transparent;
    user-select: none;
    outline: none;
    background: center no-repeat;
    background-size: contain;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='11' height='16' viewBox='0 0 11 16'%3E%3Cdefs%3E%3Cpath id='a' d='M274 135.3l1.2 1.3-5.4 5.4 5.7 5.6-1.5 1.4-7-7 1.4-1.4z'/%3E%3C/defs%3E%3Cuse fill='%23b2b2b2' xlink:href='%23a' transform='translate%28-266 -134%29'/%3E%3C/svg%3E");
    position: relative;
  }

  .owl-next {
    transform: rotate(180deg);
  }
}