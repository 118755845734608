
#contact {
  text-align: right;

  @media (max-width: $screen-sm-max) {
    margin: 0 auto;
    padding: 10px 0 8px;
  }

  @media (min-width: $screen-lg) {
    width: 505px;
    height: 84px;
    margin-left: 235px;
  }
}

#contact img {
  float: left;

  @media (max-width: $screen-sm-max) {
    width: 20px;
    height: auto;
  }
}

#contact tr {
  @media (max-width: $screen-sm-max) {
    td:first-child {
      padding-right: 10px;
    }

    td:last-child{
      padding-left: 10px;
    }
  }
}

#contact tr td .number {
  @media (max-width: $screen-sm-max) {
    color: #b5282f;
    font-family: 'PT Sans Narrow', sans-serif;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.35px;
  }

  @media (min-width: $screen-lg) {
    color: #b5282f;
    font-size: 22px;
    font-weight: bold;
    vertical-align: top;
    margin-left: 5px;
    margin-right: 55px;
    position: static !important;
  }
}

#contact tr td span {
  @media (max-width: $screen-sm-max) {
    color: #052228;
    font-family: 'PT Sans Narrow', sans-serif;
    font-size: 13px;
    letter-spacing: 0.33px;
  }

  @media (min-width: $screen-lg) {
    color: #a3aebf;
    font-size: 14px;
    vertical-align: top;
    text-align: left;
    position: relative;
    top: 3px;
  }
}