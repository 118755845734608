@media (max-width: $screen-sm-max) {

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  table {
    width: auto !important;
    max-width: 100% !important;
  }

}

.screen-sm-hide {
  @media (min-width: $screen-sm) {
    display: none;
  }
}

.screen-lg-hide {
  @media (min-width: $screen-lg) {
    display: none;
  }
}

.screen-xs-max-hide {
  @media (max-width: $screen-xs-max) {
    display: none;
  }
}

.screen-sm-max-hide {
  @media (max-width: $screen-sm-max) {
    display: none;
  }
}