@media (min-width: $screen-lg) {

  nav, .nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .nav {
    margin: 0;
    padding: 0;
    padding-top: 5px;

    @media (min-width: $screen-lg) {
      position: absolute;
      left: 217px;
      bottom: 10px;
      width: 760px;
      height: 20px;
      border-top: 1px solid #d1d3d4;
    }
  }

  .nav li a {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .nav ul {
    height: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 35px;
    padding: 0;
    margin: 0;
    z-index: 100;

  }

  .nav li {
    float: left;
    position: relative;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .nav li a {

    font-weight: bold;
    color: #333333;
    display: block;
    font-size: 15px;
    line-height: 25px;
    border-right: 1px solid #ebebeb;
    padding: 0px 10px;
    text-decoration: none;
    transition: 0.5s;
    color: #647b98;
  }

  .nav li a:hover {
    color: #b5282f !important;
    text-decoration: underline;
  }

  .nav .info a {
    padding-right: 0px;
    padding-left: 10px;
    border-right: none;
  }

  .nav .info_1 a {
    border-left: none;
    padding-left: 0px;
    padding-right: 10px;
  }

  .nav .gal {
    margin-bottom: 2px;
    border: none;
  }

  .nav li:hover > a {
    color: #4c688c;
  }

  .subs li a:hover {
    color: #b5282f;
  }

  .nav li:hover ul.subs {
    height: auto;
    width: 180px;
    margin-top: -10px;
    z-index: 1000;
    position: absolute;
  }

  .nav ul li {
    opacity: 0;
    transition: 0.5s;
    width: 100%;
  }

  .nav li ul li {
    transition-delay: 0s;
  }

  .nav li:hover ul li {
    opacity: 1;
    transition-delay: 0.5s;
  }

  .nav ul li a {
    background-color: #7a7a7a;
    border-color: #6E67A6;
    color: #fff;
    line-height: 1px;
    transition: 1.5s;
    border-radius: 0px;
    opacity: 0.8;
  }

  .nav li:hover ul li a {
    line-height: 35px;
  }
}