#right {

  @media (max-width: $screen-sm-max) {
    order: 3;
  }

  @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    float: left;
    width: 40%;
    padding-left: 15px;

    .newss,
    .footer-consult {
      float: none;
      width: 100%;
      border: none;
    }
  }

  @media (min-width: $screen-lg) {
    width: 260px;
    height: 100%;
    float: right;
  }
}

#right h2 {
  font-size: 20px;
  font-weight: bold;
  color: #647b98;

  @media (min-width: $screen-lg) {
    margin-top: 20px;
  }
}
