
#title {
  max-width: 320px;
  margin: 0 auto;
  background-color: #b5282f;
  text-align: center;
  margin-top: 25px;
  border-radius: 10px;

  @media (min-width: $screen-lg) {
    width: 429px;
    height: 38px;
  }
}

#title h2 {
  max-width: 320px;
  margin: 0;
  padding-top: 8px;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;

  @media (max-width: $screen-sm-max) {
    padding: 5px;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
  }
}

#title_2 {
  max-width: 320px;
  margin: 0 auto;

  @media (min-width: $screen-lg) {
    border-radius: 10px;
    width: 429px;
    height: 38px;
  }
}

#title_2 h2 {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;

  @media (max-width: $screen-sm-max) {
    padding: 5px;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    background-color: #647b98;
    border-radius: 10px;
  }

  @media (min-width: $screen-lg) {
    padding-top: 8px;
  }
}
