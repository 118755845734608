
#center {

  @media (max-width: $screen-sm-max) {
    order: 1;
  }

  @media (min-width: $screen-sm) {
    order: 2;
    width: 60%;
    height: 100%;
    float: left;
    padding: 0 15px;
    border-right: 1px solid #e8e9e9;
    border-left: 1px solid #e8e9e9;
  }

  @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    border: none;
  }

  @media (min-width: $screen-lg) {
    width: 680px;
    margin-left: 5px;
  }

  a {
    color: #247ec4;
  }
}

.center_new {
  border-bottom: 1px solid #e8e9e9;
  margin-bottom: 25px;

}

.center_new h4 {
  color: #b5282f;
  margin-bottom: 10px;
  font-size: 17px;
}

.center_new span {
  font-size: 14px;
  color: #8a8a8a;
}

.center_new p {
  margin-top: 16px;
  color: #333333;

  padding-right: 5px;
  margin-bottom: 10px;
}

.center_new a {
  color: #247ec4;
  font-size: 14px;
  float: right;
  margin-top: -25px;
}