.page-feedback {

  @media (max-width: $screen-sm-max) {
    .table-responsive td {
      padding: 0;

      img {
        display: block;
        max-width: 100%;
        margin: 0 auto;
      }
    }

    textarea,
    input {
      width: 100%;
      margin-top: 5px;
      padding: 10px;
      resize: none;
      border-radius: 3px;
      border: 1px solid #dbdee1;
      background-size: cover;
      max-width: 300px;

      &.button {
        color: #647b98;
        font-family: 'PT Sans Narrow', sans-serif;
        font-size: 18px;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
  }

  @media (min-width: 400px) and (max-width: $screen-sm-max) {
    .table-responsive td p {
      text-align: center;
    }
  }

}
