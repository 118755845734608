
#trendspb {
  text-align: center;
  font-size: 13px !important;
  font-family: 'PT Sans Narrow', sans-serif;
  color: #647b98;
}

#trendspb a {
  color: #647b98;
  text-decoration: none;
}

#trendspb a:hover {
  color: #647b98;
  text-decoration: underline;
}

#trendspb a:visited {
  color: #647b98;
  text-decoration: none;
}