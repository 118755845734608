
#slider{
  width:1005px;
  height: 290px;
  margin:0 auto;
  margin-top:20px;
  background-color: #ffffff;
  box-shadow: 1px 1px 3px 2px #a8a9ab;
  position:relative;
  z-index: 50;
  border-radius: 10px;
  -webkit-border-radius:10px;
  -moz-border-radius:10px;
  overflow:hidden;

  @media (max-width: $screen-sm-max) {
    display: none;
  }
}

.slider{
  border-radius: 10px;
  -webkit-border-radius:10px;
  -moz-border-radius:10px;
}

.sliderContent{
  border-radius: 10px;
  -webkit-border-radius:10px;
  -moz-border-radius:10px;
  overflow:hidden;
}

#slider .item{
  border-radius: 10px;
  -webkit-border-radius:10px;
  -moz-border-radius:10px;
  overflow:hidden;
}

#slider .item img{
  border-radius: 10px;
  -webkit-border-radius:10px;
  -moz-border-radius:10px;
}

/*slide jquery*/
.slider {
  width:1005px;
  height: 290px;
  margin:0 auto;
  padding-bottom:26px;
}

.sliderContent {
  width:1005px;
  height: 290px;
  margin:0 auto;
  clear:both;
  overflow:hidden;
}


.sliderContent .item {
  position:absolute;
  width:1005px;
  height: 290px;
  margin:0 auto;
  background:#fff; }

.sliderBullets {
  position:absolute;
  bottom:15px;
  left:50%;
  z-index:50;
  margin-left:-45px;
}
.sliderBullets a {
  display:block;
  float:left;
  text-indent:-9999px;
  outline:none;
  margin-left:5px;
  width:10px;
  height:11px;
  background:url(../images/bullets.png) no-repeat;

}

.sliderBullets .active {
  background-position:0 -12px;
}

.sliderContent a {
  outline:none;
}
.sliderArrows a {display:none;}
