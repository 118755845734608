.responsive-container {
  //max-width: $screen-xs - 30px;
  width: calc(100% - 30px);
  margin: 0 auto;

  @media (min-width: $screen-sm) {
    max-width: $screen-sm;
  }

  @media (min-width: $screen-lg) {
    max-width: $screen-lg;
  }
}
