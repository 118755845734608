.page-front-page {

  #left {
    display: block;
  }

  #center {
    @media (min-width: $screen-lg) {
      width: 430px;
    }
  }

  #right {
    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
      width: 100%;

      .newss,
      .footer-consult {
        float: left;
        width: 50%;
      }
    }
  }
}
