.navigation {
  @media (max-width: $screen-sm-max) {
    position: fixed;
    z-index: 80;
    top: 100px;
    right: 0;
    height: calc(100vh - 100px);
    width: 80%;
    max-width: 300px;
    padding: 50px 20px;
    background-color: white;
    box-shadow: 0 5px 3px 1px #a8a9ab, inset 0 7px 4px -5px #a8a9ab;
    transform: translateX(100%);
    transition: 0.2s;

    @media (max-height: 500px) {
      padding: 20px;
    }

    &.active {
      transform: translateX(0);
    }

    ul {
      margin: 0;
      padding: 0;

      ul {
        padding-left: 15px;
      }
    }

    li {
      list-style: none;
    }

    a,
    a:visited {
      font-weight: 700;
      display: block;
      font-size: 15px;
      line-height: 25px;
      padding: 0 10px;
      text-decoration: none;
      transition: .5s;
      color: #647b98;
    }

    .gal {
      display: none;
    }

    & > .menu {

    }
  }
}

.navigation__inner {
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  @media (max-width: $screen-sm-max) {
    display: flex;
    flex-flow: column;

    & > .menu {
      order: 2;
    }
  }
}

.navigation__panel {
  @include clearfix;

  padding: 5px 20px 3px;
  border-bottom: 1px solid #e8e8e8;

  @media (min-width: $screen-lg) {
    display: none;
  }
}

.navigation__logo {
  width: 50px;
  height: 30px;
  float: left;
  background: url('../images/logo.png') center no-repeat;
  background-size: contain;
}

.navigation__button {
  position: relative;
  top: 4px;
  display: inline-block;
  float: right;
  width: 22px;
  height: 22px;
  font-size: 0;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAUBAMAAACQZWsAAAAAAXNSR0IB2cksfwAAABJQTFRFZHyXY3qYZHuYY3uXZHqYAAAAXdHTjAAAAAZ0Uk5TQN//4PAACQDSmQAAACpJREFUeJxjYFSCAkUGBiMYW0mAwQnONmBghjGVGRhC4SCAKPZgM3+YAAD42C2RbxcpPAAAAABJRU5ErkJggg==');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  border-radius: 0;
  border: none;
  box-shadow: none;
  outline: none;
}

.navigation__lang {
  margin-bottom: 20px;

  img {
    position: relative;
    top: 4px;
    margin-right: 5px;
  }

  @media (min-width: $screen-lg) {
    display: none;
  }
}