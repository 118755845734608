
#left {
  display: none;

  @media (max-width: $screen-sm-max) {
    order: 2;
  }

  @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    order: 1;
    width: 40%;
    padding-top: 20px;
    padding-right: 15px;
  }

  @media (min-width: $screen-lg) {
    width: 260px;
    height: 100%;
    float: left;
  }
}

#left .mini_sert {
  overflow: hidden;

  @media (max-width: $screen-sm-max) {
    text-align: center;
    
    img {
      width: 100%;
      max-width: 320px;
    }
  }

  @media (min-width: $screen-lg) {
    margin-left: 19px;
    margin-top: 16px;
    width: 225px;
    height: 160px;
    border-radius: 10px;
  }
}

#left .button {
  border-radius: 7px;
  overflow: hidden;
  display: block;

  @media (max-width: $screen-sm-max) {
    width: 100%;
    background-size: cover;

    a {
      border: none !important;
    }
  }

  @media (min-width: $screen-lg) {

  }
}

#left .button:hover {
  border-radius: 7px;
}

#left .span {
  @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    margin-top: -30px;
  }
}

#left h2 {
  font-size: 20px;
  font-weight: bold;
  color: #647b98;
  margin-top: 20px;

  @media (min-width: $screen-lg) {
    padding-left: 20px;
  }
}

#left p {
  font-size: 15px;
  color: #333333;
  text-align: justify;
  margin-top: 15px;

  @media (max-width: $screen-sm-max) {
    @include clearfix;

    border: none !important;
  }

  @media (min-width: $screen-lg) {
    padding-left: 20px;
    padding-right: 10px;
  }
}

#left .span p {
  font-size: 16px;
  color: #333333;
  margin-top: 40px;
  text-align: justify;

  @media (min-width: $screen-lg) {
    padding-left: 20px;
    padding-right: 10px;
  }
}

#left span p a, #left span p a, #left span p i {
  font-size: 13px;
  font-weight: bold;
}

#left p a {
  color: #247ec4;

  @media (max-width: $screen-sm-max) {
    float: right;
    margin-top: 10px;

    &:first-letter {
      text-transform: uppercase;
    }
  }
}

#left h3 {
  font-size: 19px;
  font-weight: bold;
  color: #b5282f;
  margin-bottom: 10px;

  @media (min-width: $screen-lg) {
    padding-left: 20px;
  }
}

#left ul {
  margin-bottom: 30px;

  @media (max-width: 400px) {
    columns: 2;
  }

  @media (max-width: $screen-sm-max) {
    padding-left: 0;

    li {
      margin-bottom: 10px;
    }
  }

  @media (min-width: $screen-lg) {
    padding-left: 34px;
  }
}

#left ul li {
  list-style: none;
}

#left ul li a {
  font-size: 16px;
  color: #5493cc;
}

#left ul li span {
  font-size: 14px;
  color: #7d7d7d;
}