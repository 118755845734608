
#addr tr td {
  padding-bottom: 40px;
}

#addr tr td > div {
  border: 1px solid black;
  width: 400px;
  overflow: hidden;
}
