.page-header {
  @media (max-width: $screen-sm-max) {
    margin-top: 19px;
    color: #647b98;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
  }

  @media (min-width: $screen-lg) {
    display: none;
  }
}