
#footer {
  background-color: #ffffff;
  box-shadow: 1px 1px 3px 2px #a8a9ab;
  clear: both;
  border-radius: 10px;
  padding-top: 5px;

  @media (max-width: $screen-sm-max) {
    display: none;
  }

  @media (min-width: $screen-lg) {
    width: 1005px;
    height: 120px;
    margin: 0 auto;
    margin-top: 20px;
  }
}

#footer ul {
  text-align: right;
  float: left;
  width: auto;
  padding-right: 15px;
  border-right: 1px solid #e8e9e9;
  margin-left: 16px;
  padding-left: 0px;
}

#footer ul li {
  list-style: none;
  color: #6d829e;
  font-size: 15px;
  margin-bottom: 2px;
}

#footer ul li span {
  color: #b5282f;
}

#footer .footer_contact {
  margin-right: 35px;
}

#footer .footer_contact, #footer .footer_contactt {
  text-align: left;
  border: none;
}

#footer .footer_contact li a, #footer .footer_contactt li a {
  font-size: 15px;
  color: #77a6d5;
}

#footer .footer_contact li a:hover, #footer .footer_contactt li a:hover {
  color: #b5282f;
  text-decoration: none;
}

#footer .footer_contactt {
  margin-left: -35px;
  border-right: 1px solid #e8e9e9;
  padding-right: 30px;
}

#footer_logo {
  height: 90px;
  width: 154px;
  float: right;
  margin-top: 12px;
  background-image: url(../images/footer_logo.png);
  position: relative;
  left: -7px;
}

#footer_logo span {
  font-size: 12px;
  color: #a8a8a8;
  position: absolute;
  bottom: 15px;
  right: 5px;
}

#footer > ul {
  margin-top: 5px;
}
