
#lang {
  position: absolute;
  top: 15px;
  right: 20px;
  width: 115px;
  height: 15px;

  @media (max-width: $screen-sm-max) {
    display: none;
  }
}

#lang a {
  font-size: 14px;
  font-weight: bold;
  margin-right: 10px;
}

#lang .rus {
  color: #b5282f;
  text-decoration: none;
}

#lang .eng {
  color: #5493cc;

  margin-top: 4px;
}