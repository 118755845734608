.button {
  width: 226px;
  height: 41px;
  background-image: url(../images/button.png);
  display: block;
  cursor: pointer;
  margin-bottom: 8px;
  margin-top: 10px;

  @media (max-width: $screen-sm-max) {
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: $screen-lg) {
    margin-left: 20px;
  }
}

.button a {
  width: 226px;
  height: 28px;
  text-align: center;
  vertical-align: middle;
  color: #647b98;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  margin: 0 auto;
  display: block;
  padding-top: 12px;

  @media (min-width: $screen-lg) {

  }
}

.button:hover a {
  color: #b5282f;
  border-bottom: 1px solid #CCCCCC;
}

.button:hover {
  background-position: 0px 41.5px;

}

.konsultaciya_button,
.otzyvy_button {

  @media (max-width: $screen-sm-max) {
    margin: 0 auto;

    a {
      margin: 0 auto;
    }
  }
}