
#slide_block{
  width:251px;
  height: 241px;
  position:absolute;
  top:25px;
  right:26px;
  background-color:#7a7a7a;
  opacity:0.8;
  z-index: 100;
  border-radius: 10px;
  -webkit-border-radius:10px;
  -moz-border-radius:10px;
}
#slide_block h2{
  background-color:#b5282f;
  font-size:18px;
  font-weight:bold;
  padding:9px 14px;
  color:#ffffff;
}
#slide_block ul{
  padding-left: 30px;

}
#slide_block ul li{

  padding-bottom: 5px;
  list-style: none inside;
}
#slide_block ul li span{
  margin-left:-20px;
  color:#d1d1d1;
}
#slide_block ul li a{
  text-decoration: none;
  color:#d1d1d1;
  font-size:15px;
  margin-left:5px;
}
#slide_block ul li a:hover{
  color:#ffffff;
}